@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.section1 {
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    /* height: fit-content; */
    /* height: 60rem; */
    /* height: 15rem; */

}

.section-1-container {
    width: 100%;
    background-color: #ffffff00;
    height: auto;
    text-align: center;
    justify-content: center;
    padding-top: 10rem;
    letter-spacing: .5rem;
    z-index: 0;

}

#fullscreen-video-smoke {
    position: absolute;
    z-index: 0;
    opacity: 10%;
    width: 100%;
}

.ut-effect-letter {
    /* position: absolute; */
    background: -webkit-linear-gradient(45deg, #4b0a0a, #ff0202);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 6rem;
    text-align: center;
    font-family: MUARA;
}

#aatwpic {
    /* position: absolute; */
    width: 60%;
    height: auto;
    /* padding-top: 12rem; */
    bottom: 0px;
    opacity: 0%;
    transition: opacity 6s ease-out;
    animation: aatwpic 1s 1.75s forwards;
    /* animation-duration: 5s; */
    z-index: -1;
}


@keyframes aatwpic {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#lineup-info-button {
    opacity: 0%;
    transition: opacity 6s ease-out;
    animation: lineup-info-button 1s 1.75s forwards;
}

@keyframes lineup-info-button {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#tppic {
    /* position: absolute; */
    width: 60%;
    height: auto;
    /* padding-top: 12rem; */
    bottom: 0px;
    opacity: 0%;
    transition: opacity 6s ease-out;
    animation: aatwpic 1s 1.75s forwards;
    /* animation-duration: 5s; */
    z-index: -1;
}

@keyframes tppic {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.and-sign-lu {
    width: 80%;
    height: auto;
    bottom: 0px;
    opacity: 0%;
    transition: opacity 6s ease-out;
    animation: aatwpic 1s 1.75s forwards;
    /* animation-duration: 5s; */
    z-index: 0;
}

@keyframes and-sign-lu {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.section2 {
    width: 100%;
    height: 30rem;
    background-color: #ffffff;
}

.and-sign-lu {
    width: auto;
    font-family: MUARA;
    font-size: 8rem;
    background: -webkit-linear-gradient(45deg, #4b0a0a, #ff0202);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    justify-content: center;
    padding-top: 5rem;
    z-index: -1;
}



.section2-container-lu {
    width: 100%;
    z-index: -1 !important;

}

.section-2-lu {
    width: 100%;
}

#tppic {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}




@media only screen and (max-width: 1040px) {
    .and-sign-lu {
        font-size: 3rem !important;
        padding-top: 3rem;
    }

}

@media only screen and (max-width: 800px) {
    .ut-effect-letter {
        font-size: 3rem !important;
    }
}

@media only screen and (max-width: 490px) {
    .ut-effect-letter {
        font-size: 2.5rem !important;
    }

    .section-1-container {
        padding-top: 5rem;
    }
}

.modal-holder {
    padding-top: 2rem;
}

.modal-holder2 {
    padding-bottom: 2rem;
}