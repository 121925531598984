.schedule-container {
  width: 90%; /* Use a percentage width for responsiveness */
  max-width: 1000px; /* Increase max-width to give more space */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.schedule-container h2 {
  text-align: center;
  margin-bottom: 50px;
  color: #333;
}

.schedule-list {
  list-style: none;
  padding: 0;
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid #ddd;
}

.schedule-time {
  font-weight: bold;
  color: #ac7b32;
  margin-right: 20px;
}

.schedule-event {
  flex: 1;
  text-align: right;
  color: #555;
  font-size: 18px;
}

@media (max-width: 768px) {
  .schedule-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .schedule-time {
    margin-bottom: 5px;
  }

  .schedule-event {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .schedule-container {
    padding: 10px;
  }

  .schedule-item {
    padding: 8px 0;
  }

  .schedule-time {
    font-size: 14px;
  }

  .schedule-event {
    font-size: 14px;
  }
}
