@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.section1-tickets {
    width: 100%;
    overflow-x: hidden;
    height: 60rem;
    padding-top: 18rem;
    /* overflow-y: hidden; */
    text-align: center;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg');
    background-size: cover;
}

.sec-1-ht-tickets {
    /* text-align: center; */
    width: 100%;
    color: #4f3a2b;
    font-family: ironmonger;
    font-size: 3.25rem;
    /* text-decoration: underline overline; */
    text-underline-offset: 17px;
    /* text-decoration-color: #1469b9; */
    
}

.sec-1-container-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sec-1-text-tickets {
    font-family: MUARA;
    font-size: 2rem;
    width: 80%;
    text-align: center;
    padding: 6rem;
}

#jdf-tickets {
    text-align: center;
    justify-content: center;
    padding-top: 4rem;
    width: 30%;
}

.btn-tickets {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@media only screen and (max-width: 860px ) {

    .section1-tickets {
        padding-top: 14rem;
    }

    .sec-1-ht-tickets {
        font-size: 36px;
    }

    .sec-1-text-tickets {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 770px ) {

    .section1-tickets {
        padding-top: 14rem;
    }

    .sec-1-ht-tickets {
        font-size: 2rem;
    }

    .sec-1-text-tickets {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 520px ) {

  
    .sec-1-ht-tickets {
        font-size: 26px;
    }
    
}

@media only screen and (max-width: 440px ) {

    .section1-tickets {
        padding-top: 14rem;
    }

    .sec-1-ht-tickets {
        font-size: 20px;
    }

    .sec-1-text-tickets {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 400px ) {

    .section1-tickets {
        padding-top: 15rem;
    }

    .sec-1-ht-tickets {
        font-size: 16px;
    }

    .sec-1-text-tickets {
        padding-top: 3rem;
        font-size: 1.5rem;

    }
}