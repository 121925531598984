@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.section1-sponsors {
    width: 100%;
    /* height: 50rem; */
    height: fit-content;
    padding-top: 15rem;
    background: url('../images/paper.jpg');
    background-size: cover;
    align-items: center;
    padding-bottom: 5rem;
}

.sec-1-sponsors-ht {
    font-family: MUARA;
    font-size: 5rem;
    text-align: center;
    padding-top: 3rem;
}

.sponsor-logo-images {
    display: flex;
    flex-direction: column;
}

.logo-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-around;
    justify-content: space-evenly;
    /* padding-top: 5rem; */
    padding-bottom: 5rem;
}

.logo-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
    padding-top: 5rem;
}

.logo-3 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
    padding-top: 5rem;
}

.logo-4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
    padding-top: 5rem;
}

.logo-5 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
    padding-top: 5rem;
}

.logo-6 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
    padding-top: 5rem;
}

#asb {
    width: 30%;
    height: 30%;
}

#mcd {
    width: 18%;
    height: 18%;
    padding-right: 5rem;
}

#ed {
    width: 18%;
    height: 18%;
    padding-top: 5rem;
    padding-left: 3rem;
}

#chamber {
    width: 20%;
    height: 20%;
}

#artisan {
    width: 25%;
    height: 25%;
    padding-top: 4rem;
}

#wayland {
    width: 25%;
    height: 25%;
    padding-top: 4rem;
}

#lions {
    width: 20%;
    height: 20%;
    padding-top: 5rem;
}

#slate {
    width: 20%;
    height: 20%;
    padding-top: 5rem;
}

#lonestar {
    width: 30%;
    height: 30%;
    padding-top: 5rem;

}

#prosperity {
    padding-top: 1rem;
    width: 20%;
    height: 20%;
}

#burger-king {
    width: 10%;
    height: 10%;
}

#row1-logos {
    width: 30%;
    height: 30%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#row2-logos {
    width: 25%;
    height: 25%;
}

#row3-logos {
    width: 25%;
    height: 25%;
    padding-top: 5rem;
}

#row4-logos {
    width: 25%;
    height: 25%;
    padding-top: 1rem;
    /* padding-top: 7rem; */
}

#vexus {
    padding-top: 3rem;
    width: 20%;
    height: 20%;
}

#higgin {
    padding-top: 4rem;
    width: 20%;
    height: 20%;
}

#dodson {
    padding-top: 6rem;
    width: 20%;
    height: 20%;
}

#western {
    padding-top: 4rem;
    width: 15%;
    height: 15%;
}

@media only screen and (max-width: 1100px) {
    #artisan {
        padding-top: 2rem;
    }

    #ed {
        padding-top: 3rem;
        padding-left: 2rem;
    }

    #row3-logos {
        padding-top: 4rem;
    }
}

@media only screen and (max-width: 950px) {
    #ed {
        padding-top: 3rem;
        padding-left: 1rem;
    }

}

@media only screen and (max-width: 925px) {
    #ed {
        padding-left: 0rem;
        padding-top: 3rem;
    }

    #row3-logos {
        padding-top: 4rem;
    }

}

@media only screen and (max-width: 900px) {
    .section1-sponsors {
        padding-top: 12rem;
    }

    .sec-1-sponsors-ht {
        font-size: 4rem;
    }

    /* .logo-1 {
        flex-direction: column;
        align-items: center;
    } */
    #row1-logos {
        padding-top: 3rem;
        width: 50%;
    }
}

@media only screen and (max-width: 870px) {
    #mcd {
        padding-right: 3rem;
    }

}

@media only screen and (max-width: 730px) {
    .sec-1-sponsors-ht {
        font-size: 3rem;
    }

    .logo-1 {
        padding-bottom: 2rem;
    }

    #row1-logos {
        padding-bottom: 3rem;
    }

}

@media only screen and (max-width: 725px) {
    #artisan {
        padding-top: 2rem;
    }

    #wayland {
        padding-top: 0rem;
    }

    #lions {
        padding-top: 0rem;
    }

    #slate {
        padding-top: 0rem;
    }

    #lonestar {
        padding-top: 2rem;
    }

}

@media only screen and (max-width: 485px) {
    .logo-1 {
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    #row1-logos {
        padding-bottom: 2rem;
        width: 70%;
    }

    #row2-logos {
        padding-bottom: 3rem;
        width: 40%;
    }

    #ed {
        padding-left: 0rem;
        padding-top: 1rem;
    }

    #row3-logos {
        padding-top: 1.5rem;
        width: 35%;
    }

    #prosperity {
        padding-top: 0rem;
        width: 30%;
    }

    #burger-king {
        padding-top: 2rem;
        width: 17%;
    }

    #higgin {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 30%;
    }

    #dodson {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 30%;
    }

    #western {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 30%;
    }

}