@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.modal-content {
    text-align: left;
    letter-spacing: 0;
    overflow-y: scroll;
    height: 700px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 800px;
    min-width: 300px;
}

.modal-text {
    font-family: 'Roboto Condensed', sans-serif;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

body.active-modal2 {
    overflow-y: hidden;
}

.btn-modal2 {
    /* padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px; */
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.modal2, .overlay2 {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay2 {
    background: rgba(49,49,49,0.8);
}

.modal-content2 {
    font-family: 'Roboto Condensed', sans-serif;
    overflow-y: scroll;
    height: 730px;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 800px;
    min-width: 300px;
}

.close-modal2 {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

@media only screen and (max-width: 900px) {
    .modal-content {
        height: 675px;
        top: 56%
    }

    .modal-content2 {
        height: 675px;
        top: 56%
    }
}

@media only screen and (max-width: 400px) {
    .btn-modal {
        font-size: 13px;
    }

    .btn-modal2 {
        font-size: 13px;
    }
}