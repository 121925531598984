.ticket-container {
    width: 90%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .ticket-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .ticket-list {
    list-style: none;
    padding: 0;
  }
  
  .ticket-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .ticket-info {
    margin-bottom: 10px;
  }
  
  .ticket-type {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .ticket-price {
    font-size: 1.1rem;
    color: #333;
  }
  
  .ticket-fee {
    font-size: 0.9rem;
    color: #888;
  }
  
  .ticket-pdf {
    margin-top: 10px;
  }
  
  .ticket-pdf a {
    color: #007bff;
    text-decoration: none;
  }
  
  .ticket-pdf a:hover {
    text-decoration: underline;
  }
  
  .purchase-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: none;
  }
  
  .purchase-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 480px) {
    .ticket-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .ticket-type {
      margin-bottom: 5px;
    }
  }
  