@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.section1-vendors {
    width: 100%;
    overflow-x: hidden;

    /* tickets active */
    /* height: 60rem; */

    padding-top: 10rem;
    /* overflow-y: hidden; */
    text-align: center;
    /* background: linear-gradient(to top, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg'); */
    background-size: cover;
}

.sec-1-ht-vendors {
    /* text-align: center; */
    width: 100%;
    color: black;
    font-family: ironmonger;
    font-size: 3.25rem;
    text-decoration: underline overline;
    text-underline-offset: 17px;
    text-decoration-color: #1469b9;
    
}

.sec-1-container-v {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sec-1-text-v {
    font-family: MUARA;
    font-size: 2rem;
    width: 80%;
    text-align: center;
    padding: 6rem;
}

#jdf-v {
    text-align: center;
    justify-content: center;
    padding-top: 4rem;
    width: 30%;
}

.btn-v {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    
    /* tickets active */
    /* display: block; */
    display: none;
}

@media only screen and (max-width: 770px ) {

    .section1-vendors {
        padding-top: 14rem;
    }

    .sec-1-ht-vendors {
        font-size: 2rem;
    }

    .sec-1-text-v {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 440px ) {

    .section1-vendors {
        padding-top: 7rem;
    }

    .sec-1-ht-vendors {
        font-size: 1.7rem;
    }

    .sec-1-text-v {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 400px ) {

    .section1-vendors {
        padding-top: 15rem;
    }

    .sec-1-ht-vendors {
        font-size: 1.5rem;
    }

    .sec-1-text-v {
        padding-top: 3rem;
        font-size: 1.5rem;

    }
}

.btb-v {
    text-decoration: none;
}

.rules-text {
    text-decoration: underline;
    font-family: MUARA;
    color: #b21f24;
    font-size: 2.5rem;
    padding-top: 2rem;

    /* tickets active */
    display: none;
}