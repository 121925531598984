@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');

@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

.navbar-container {
    width: 100vw;
}

.navbar-container {
    position: fixed;
    z-index: 999;
}

.top-line-nav {
    width: 100%;
    height: 1.5rem;
    background-color: rgb(255, 255, 255);
    text-align: right;
    font-size: .9rem;
    transition: all 0.3s ease;
}

.top-nav-text {
    padding-right: 1rem;
}

.bottom-line-nav {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8rem;
    color: white;
    font-family: ironmonger;
    /* font-family: 'Alfa Slab One', cursive; */
    background: #b21f24;
    /* background: linear-gradient(45deg, #0088ca, #1469b9); */
    transition: all 0.3s ease;

}

.nav-link {
    /* transition: 0.2s; */
    width: 20%;
    font-size: 1.75rem;
    /* margin-right: 5rem;
    margin-left: 5rem; */
    align-self: center;
    text-align: center;
    text-decoration: none;
    color: white;
}

#jdlogo {
    width: 13rem;
    margin-right: 5rem;
    margin-left: 5rem;
    padding-top: 5rem;
    transition: all 0.3s ease;

}

.top-line-nav-scrolled {
    /* width: 100%;
    height: 1.5rem;
    background-color: rgb(255, 255, 255);
    text-align: right;
    font-size: .9rem; */
    display: none !important;
    transition: all 0.3s ease;
}

.bottom-line-nav-scrolled {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 6rem;
    color: white;
    font-family: ironmonger;
    /* font-family: 'Alfa Slab One', cursive; */
    background: #b21f24;
    /* background: linear-gradient(45deg, #0088ca, #1469b9); */
    transition: all 0.3s ease;

}

#jdlogo-scrolled {
    width: 5.6rem;
    margin-right: 5rem;
    margin-left: 5rem;
    /* padding-top: 5rem; */
    transition: all 0.3s ease;
}

/* @media only screen and (max-width: 1440px) {
    .nav-link {
        margin-right: 3rem;
        margin-left: 3rem;
    }
} */

@media only screen and (max-width: 1260px) {
    /* .nav-link {
        margin-right: 2rem;
        margin-left: 2rem;
    } */

    #jdlogo {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

@media only screen and (max-width: 1130px) {
    .nav-link {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1090px) {
    #jdlogo-scrolled {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media only screen and (max-width: 900px) {
    .nav-link {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media only screen and (max-width: 900px) {
    .navbar-content {
        display: none;
    }

    .red-bar {
        height: 9rem;
    }

    #jdlogo-scrolled {
        padding: .5rem !important;
    }
}

@media only screen and (min-width: 900px) {
    .topnav {
        display: none;
    }


}

@media only screen and (max-width: 411px) {
    .topnav a {
        font-size: 10px;
        padding: 14px 11px !important;
    }
}

@media only screen and (max-width: 360px) {
    .topnav a{
        padding: 14px 8px !important;
    }


}

.topnav {
    overflow: hidden;
    background-color: #b21f24;
    position: relative;
}

.topnav #myLinks {
    display: none;
}

.topnav a {
    float: left;
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 12px;
}

#myLinks {
    font-size: .1rem;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
}

.topnav a.icon {
    float: right;
}

.topnav a:hover {
    background-color: #b21f24;
    color: black;
}

.active {
    /* background-color: #04AA6D; */
    color: white;
}


.container-links {
    display: flex;
    flex-direction: column;
}



