@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.section1-info {
    width: 100%;
    height: fit-content;
    padding-top: 15rem;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg');
    background-size: cover;
    align-items: center;
}

.sec-1-info-ht {
    text-align: center;
    font-family: MUARA;
    font-size: 6rem;
}

.sec-1-date-info {
    padding-top: 4rem;
    padding-bottom: 2rem;
    font-family: MUARA;
    color: #b21f24;
    font-size: 3rem;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-thickness: 3px;
    text-decoration-color: #1360b9;
    /* padding-bottom: 5rem; */

}

.sec-2-info-ht {
    text-align: center;
    font-family: MUARA;
    font-size: 42px;
    padding-top: 5rem;
}

.sec-2-date-info {
    padding-top: 4rem;
    font-family: MUARA;
    color: #b21f24;
    font-size: 3rem;
    text-align: center;
    /* text-decoration: underline; */
    /* text-underline-offset: 20px; */
    /* text-decoration-thickness: 3px; */
    /* text-decoration-color: #1360b9; */
    /* padding-bottom: 5rem; */

}

#info-sec-title {
    font-size: 30px;
    font-weight: 700;
}

#info-host {
    font-size: 24px;
    color: #1360b9;
    font-weight: 500;
}

#info-time {
    font-size: 24px;
    font-weight: 400;
    /* margin-top: 0px;
    margin-bottom: 0px; */
}

#info-admission {
    color: #b21f24;
    font-size: 24px;
}

#info-location {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 30px;
}

#info-location-bot {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.sec-2-container-info {
    width: 100%;
    height: fit-content;
}

.sched-info {
    font-family: ironmonger;
    font-size: 2.5rem;
    padding-left: 25rem;
    padding-top: 5rem;
}

.sched-p1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 4rem;
}

#sched-1-hd {
    font-weight: 700;
    font-size: 2rem;
    display: block;
    margin-left: 1rem;
    margin-right: 1rem;

}

#sched-1-hda {
    font-weight: 700;
    font-size: 2rem;
    display: block;
    margin-left: 1rem;
    margin-right: 1rem;

}

.info-it-divider {
    width: 100%;

}

.info-it-line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: #1360b9;
    height: 2px;
    width: 50%;
}

#sched-1-b {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem;
    font-weight: 500;
    justify-content: center;
}

#sched-1-bt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    font-weight: 500;
    justify-content: center;
    padding: 10px;
}

#sched-1-btb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    font-weight: 500;
    justify-content: center;
    padding: 10px;
    padding-bottom: 4rem;
}

#sched-1-gym {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;
    font-weight: 700;
    justify-content: center;
    /* color: #b21f24; */
}



#sched-1-b2 {
    display: block;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    justify-content: center;
    color: #b21f24;

}

#burrus {
    font-size: 20px;
}

.sched-p2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 5rem;
}

.buy-tickets-info {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    /* tickets active */
    visibility: hidden;
}

.info-btn {
    text-decoration: none;
}

.buy-tickets-info:hover {
    background-color: #fff;
}

.buy-tickets-info:active {
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}

@media (min-width: 768px) {
    .buy-tickets.info {
        min-width: 120px;
        padding: 0 25px;
    }
}

@media only screen and (max-width: 1250px) {
    .buy-tickets-info {
        width: 25%;
    }
}

@media only screen and (max-width: 900px) {
    .section1-info {
        padding-top: 11rem;
    }
}

@media only screen and (max-width: 850px) {
    #info-sec-title {
        font-size: 24px;
    }

    #info-host {
        font-size: 20px;
    }

    #info-time {
        font-size: 20px;
    }

    #info-admission {
        font-size: 20px;
    }

    #info-location {
        font-size: 20px;
    }

    #info-location-bot {
        font-size: 20px;
    }
}

@media only screen and (max-width: 700px) {
    .buy-tickets-info {
        width: 40%;
    }

    .sec-1-info-ht {
        font-size: 4rem;
    }

    .sec-1-date-info {
        font-size: 2rem
    }

    .sec-2-date-info {
        font-size: 2rem
    }
    
}

@media only screen and (max-width: 550px) {

    .sec-1-info-ht {
        font-size: 4rem;
    }

    .sec-2-info-ht {
        font-size: 32px;
    }

    .sec-1-date-info {
        font-size: 2rem
    }

    .sec-2-date-info {
        font-size: 32px;
    }

    .sec-2-date-info {
        font-size: 2rem
    }

    #info-sec-title {
        font-size: 20px;
    }

    #info-host {
        font-size: 16px;
    }

    #info-time {
        font-size: 16px;
    }

    #info-admission {
        font-size: 16px;
        font-weight: 500;
    }

    #info-location {
        font-size: 16px;
    }

    #info-location-bot {
        font-size: 16px;
    }
}


@media only screen and (max-width: 500px) {
    #sched-1-bt {
        font-size: 20px;
    }

    #sched-1-btb {
        font-size: 20px;
    }

    #sched-1-b {
        font-size: 20px;
    }

    #sched-1-hd {
        font-size: 26px;
    }

    #sched-1-gym {
        font-size: 26px;
    }

    #burrus{
        font-size: 16px;
    }


    #assistance-number {
        font-size: 13px;
    }

    .sec-3-title {
        font-size: 32px !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media only screen and (max-width: 480px) {
    #sched-1-hd {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 460px) {
    .sec-1-info-ht {
        font-size: 48px;
    }

    .section1-info {
        padding-top: 11rem;
    }
}

@media only screen and (max-width: 440px) {
    .buy-tickets-info {
        width: 57%;
    }

    #sched-1-hd {
        font-size: 1.5rem;
    }

    #info-sec-title {
        font-size: 18px;
    }

    #info-host {
        font-size: 14px;
    }

    #info-time {
        font-size: 14px;
    }

    #info-admission {
        font-size: 14px;
        font-weight: 500;
    }

    #info-location {
        font-size: 14px;
    }

    #info-location-bot {
        font-size: 14px;
    }

    .sec-1-date-info {
        font-size: 26px;
    }

    .sec-2-date-info {
        font-size: 26px;
    }

    #assistance-number {
        font-size: 12px;
    }

    .sec-2-info-ht {
        font-size: 28px;
    }
}

@media only screen and (max-width: 400px) {
    .sec-3-title {
        font-size: 28px !important;
    }

    
}

#jdp-info {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding-left: 5rem;
    filter: drop-shadow(10px 10px 10px black
    );

}

.section3-info {
    padding-top: 5rem;
    width: 100%;
    height: fit-content;
    background-color: rgb(255, 255, 255);
}

.sec-3-title {
    text-align: left;
    padding-left: 5rem;
    font-size: 3rem;
    font-family: ironmonger;
    /* width: 25%; */
    padding-bottom: 3rem;
}

.sec-3-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 5rem;
}

.sec-3-body {
    width: 75%;
    text-align: left;
    padding-left: 5rem;
    font-size: 1.5rem;
}

.img-container {
    width: 40%;
}

.btn-container-info {
    padding-bottom: 5rem;
}

@media only screen and (max-width: 1110px) {
    .sec-3-body {
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 980px) {
    .sec-3-title {
        text-align: center;
        padding-left: 0rem;
    }

    .sec-3-container {
        flex-direction: column-reverse;
    }

    .img-container {
        margin: auto;
    }

    #jdp-info {
        padding-left: 3rem;

    }

    .sec-3-body {
        text-align: center;
        padding-top: 5rem;

    }
}

@media only screen and (max-width: 565px) {
    .sec-3-body {
        padding-left: 4rem;
    }

    #jdp-info {
        width: 100%;
        padding-left: 0rem;
    }

    .img-container {
        width: 50%;
    }


}

@media only screen and (max-width: 435px) {
    .sec-3-body {
        padding-left: 3.5rem;
    }
}

#assistance-number {
    padding-top: 3rem;
    font-weight: bold;
}


