@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rye&display=swap');

@font-face {
    font-family: ironmonger;
    src: url('../../fonts/ironmongerblack.ttf');
}

@font-face {
    font-family: MUARA;
    src: url('../../fonts/MUARA.ttf');
}

.red-bar {
    width: 100%;
    height: 10rem;
    /* background-color: #b21f24; red */
    background-color: #4f3a2b;
}

.under-red {
    display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: flex-end;     /* Centers vertically */
  height: 5rem;           /* Adjust height as needed */
  width: 100%;             /* Adjust width as needed */
  background-color: #000000;
  color: rgb(255, 255, 255);
  font-family: MUARA;
  font-size: 46px;
  letter-spacing: 12px;
  text-decoration: overline underline;
}

.lp-video {
    width: 100%;
    height: 1000px;
    position: relative;
    overflow: hidden;
}

.lp-video img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1800px) {
    .lp-video {
      height: 800px; /* Adjust height for medium screens */
    }
  }
  
  @media (max-width: 1440px) {
    .lp-video {
      height: 600px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 1120px) {
    .lp-video {
      height: 400px; /* Adjust height for mobile devices */
    }
  }
  
  @media (max-width: 720px) {
    .lp-video {
      height: 300px; /* Adjust height for very small devices */
    }
  }

  @media (max-width: 550px) {
    .lp-video {
      height: 250px; /* Adjust height for very small devices */
    }
  }


#fullscreen-video {
    width: 100%;
    height: 475px;
    object-fit: cover;
    z-index: -100;
    filter: brightness(50%);
}

.vid-text {
    position: absolute;
    color: white;
    font-family: MUARA;
    /* font-family: 'Alfa Slab One', cursive; */
    font-size: 12rem;
    z-index: 1;
    width: 100%;
    text-align: center;
    /* margin-top: 1rem; */
}

.vid-text-2 {
    position: absolute;
    color: white;
    font-family: 'Alfa Slab One', cursive;
    font-size: 2rem;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-top: 14rem;
}

.red-bar2 {
    text-align: center;
    width: 100%;
    height: 4rem;
    /* background-color: #b21f24; red */
    background-color: #4f3a2b;
}

.btn-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-top: 18rem;
}

.btn {
    font-family: 'Alfa Slab One', cursive;
    /* font-family: MUARA; */
    letter-spacing: .25rem;
    background-color: #b21f24;
    border-color: rgb(27, 138, 235);
    color: white;
    font-size: 20px;
    padding: 20px 60px;
    border-width: 6px;
    border-radius: 7px;
    margin: 10px 0px;
    cursor: pointer;
    /* tickets active */
    /* display: none; */
}

.sponsors-tag {
    text-decoration: none;
    width: auto;
    text-align: center;
}

#rbt {
    padding-top: .5rem;
    margin-top: 0rem;
    font-family: MUARA;
    color: #cc9a58;
    text-align: center;
    font-size: 2.5rem;
    text-decoration: underline;
    text-underline-offset: .5rem;

}

.section-2 {
    background-color: white;
    width: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg');
    background-size: cover;
    background-position: center;
    height: 100rem
}


.section-2-title {
    height: 8rem;
    justify-content: space-evenly;
    /* align-items: center; */
    width: 100%;
    display: flex;
    flex-direction: row;
}

.star {
    padding-top: 5rem;
}

.sec-2-head {
    font-family: MUARA;
    font-size: 7rem;
    padding-top: 3rem;
}

.section-2-body {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50rem;
    text-align: center;
}

.sec-2-ht-t {
    width: 100%;
    font-family: ironmonger;
    font-size: 2rem;
    /* padding-bottom: 2rem; */

}

.sec-2-ht-t2 {
    width: 100%;
    font-family: ironmonger;
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
    color: #b21f24;
}

.sec-2-ht-t3 {
    width: 100%;
    font-family: ironmonger;
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #b21f24;
    text-decoration: underline;
    text-decoration-color: #1469b9;
    text-underline-offset: 5px;

}

.sec-2-ht-t4 {
    width: 100%;
    font-family: MUARA;
    font-size: 4rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
    color: #000000
}

.sec-2-ht-t5 {
    width: 100%;
    font-family: ironmonger;
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #b21f24;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: #1469b9;

}


.sec-2-ht-t6 {
    width: 100%;
    font-family: MUARA;
    font-size: 4rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
    color: #000000
}



.sec-2-ht {
    width: 100%;
    font-family: ironmonger;
    font-size: 3.25rem;
    text-decoration: underline overline;
    text-underline-offset: 17px;
    text-decoration-color: #1469b9;
    /* text-decoration-color: #b21f24; */

}

.sec-2-ht-2 {
    width: 100%;
    font-family: MUARA;
    font-size: 3.25rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    visibility: hidden;

}

.sec-2-img-container {
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: row;
    height: 30rem;
    justify-content: center;
    justify-content: space-evenly;
}

.aatwpic-lp {
    width: 40%;
    height: 65%;
}

.lp-img-cont-text {
    font-family: ironmonger;
    font-size: 3.25rem;
    justify-content: center;
    padding-top: 8rem;
}

.tppic-lp {
    width: 40%;
    height: 65%;
}

.section-3-lp {
    width: 100%;
    height: 20rem;
}

.section-3-title-lp {
    width: 100%;
    display: flex;
    flex-direction: row;

}

.sec-3-ht {
    padding-top: 3rem;
    text-align: center;
    width: 100%;
    font-family: ironmonger;
    font-size: 3.25rem;
    text-decoration: underline;
    text-underline-offset: 17px;
    /* text-decoration-color: #b21f24; */
    text-decoration-color: #1469b9;

}

.section-4-lp {
    height: fit-content;
    padding-top: 25rem;
}

.section-4-title-lp {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.image-container-section4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    justify-content: space-evenly;
    /* height: 50rem; */
}

#jdfs-lp {
    width: 30%;
    height: 30%;
    padding-bottom: 1rem;
}

#jdf-lp {
    width: 30%;
    padding-bottom: 1rem;
}

.section-5-lp {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg');
    background-size: cover;
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* height: fit-content */
}

/* .sec-5-ht {
    font-family: MUARA;
    font-size: 8rem;
    text-decoration: underline;
    text-decoration-color: #b21f24;
} */

.sec-5-ht-1 {
    font-family: MUARA;
    font-size: 8rem;
    text-decoration: underline;
    text-decoration-color: #b21f24;
}

.map-pv {
    text-decoration: none !important;
    font-family: MUARA;
    font-size: 2.5rem;
}

.jdf-lp-map {
    width: 50%;
    padding: 1rem;
}

.section-6-lp {
    width: 100%;
    height: 18rem;
}

.sec-6-ht {
    padding-top: 5rem;
    justify-content: center;
    text-align: center;
    font-family: ironmonger;
    font-size: 2rem;
    text-decoration: underline;
    text-decoration-color: #b21f24;
    padding-bottom: 2rem;
}

.btn-lp-info {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Alfa Slab One', cursive;
    letter-spacing: .25rem;
    /* background-color: #b21f24; */
    border-color: rgb(27, 138, 235);
    color: white;
    font-size: 20px;
    padding: 20px 60px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;

}

.btn-lp-info2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Alfa Slab One', cursive;
    letter-spacing: .25rem;
    background-color: #b21f24;
    border-color: rgb(27, 138, 235);
    color: white;
    font-size: 20px;
    padding: 20px 60px;
    border-radius: 5px;
    cursor: pointer;
}

.section-7-lp {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 10rem;
}

.sec-7-h {
    font-size: 2rem;
    font-family: MUARA;
}

.social-icons {
    display: flex;
    flex-direction: row;
    margin: auto;
}

#social-icon {
    padding-left: .5rem;
    padding-right: .5rem;
}

@media only screen and (max-width: 1360px) {
    .lp-img-cont-text {
        padding-top: 5rem;
    }

    .sec-5-ht-1 {
    font-size: 90px;
    }
}

@media only screen and (max-width: 1150px) {
    .sec-2-ht-t4 {
        font-size: 45px;
    }

    .sec-2-ht-t6 {
        font-size: 45px;
    }

    .sec-2-ht {
        font-size: 45px;
    }

    .sec-2-ht-2 {
    font-size: 45px;
    }

    .section-2 {
        height: 100rem;
    }
}

@media only screen and (max-width: 1000px) {
    .lp-img-cont-text {
        padding-top: 3rem;
    }
}

@media only screen and (max-width: 1040px) {
    .star {
        display: none;
    }

    .sec-3-ht {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 950px) {
    .sec-5-ht-1 {
        font-size: 77px;
        padding-left: 1rem;
    }

    .map-pv {
    font-size: 30px;
    padding-left: 1rem;
    }

    .section-4-lp {
        padding-top: 15rem;
    }
}


@media only screen and (max-width: 850px) {
    .sec-2-head {
        font-size: 5rem;
    }

    /* tickets active */
    .vid-text {
        font-size: 10rem;
    }
}

@media only screen and (max-width: 800px) {
    .section-4-lp {
        display: none;
    }

    .section-5-lp {
        height: 43rem;
        display: flex;
        flex-direction: column;
        padding-top: 50rem
    }

    .jdf-lp-map {
        padding-top: 1rem;
        width: 70%;
    }

    .sec-5-ht {
        font-size: 4rem;
        text-align: center;
    }

    .section-6-lp {
        padding-top: 10rem;
        /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.781), rgba(255, 255, 255, 0)), url('../images/paper.jpg'); */

    }

    .sec-2-img-container {
        display: flex;
        flex-direction: column;
    }

    .aatwpic-lp {
        width: 90%;
        height: auto;
        padding: 1rem;
        padding-top: 8rem;
        margin-left: auto;
        margin-right: auto;

    }

    .lp-img-cont-text {
        padding-top: 1rem;
        font-family: ironmonger;
    }

    .tppic-lp {
        width: auto;
        padding: 1rem;

    }

    .sec-2-ht {
        text-decoration: none;
    }
}

@media only screen and (max-width: 700px) {
    .sec-2-head {
        font-size: 5rem;
    }

    .vid-text {
        font-size: 8rem;
    }

    .section-5-lp {
        padding-top: 50rem;
    }
}

@media only screen and (max-width: 638px) {
    .sec-5-ht {
        padding-top: 5rem;
    }
}

@media only screen and (max-width: 625px) {
    .sec-2-head {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 600px) {
    .section-5-lp {
        padding-top: 52rem;
    }

    /* tickets active */
    .vid-text {
        font-size: 7rem;
    }
}

@media only screen and (max-width: 592px) {
    .section-5-lp {
        padding-top: 57rem;
    }
}

@media only screen and (max-width: 591px) {
    .section-5-lp {
        padding-top: 57rem;
    }
}

@media only screen and (max-width: 560px) {
    .vid-text-2 {
        margin-top: 11rem;
    }
}

@media only screen and (max-width: 510px) {
    .sec-2-head {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 500px) {
    .section-5-lp {
        padding-top: 40rem;
    }

    .sec-2-ht-t {
        font-size: 2rem;
    }

    .sec-2-ht {
        font-size: 1.5rem;
    }

    .sec-2-ht-t4 {
        width: 99%;
        font-size: 2.5rem;
    }

    .sec-2-ht-t6 {
        width: 99%;
        font-size: 2.5rem;
    }

    .sec-2-ht-2 {
        width: 99%;
        font-size: 2rem;
    }

    .sec-6-ht {
        width: 99%;
        font-size: 1.5rem;
    }

    /* tickets active */
    .vid-text {
        font-size: 6rem;
    }
}

@media only screen and (max-width: 470px) {
    .sec-2-ht-t {
        font-size: 1.5rem;
    }

    .sec-2-ht-t4 {
        font-size: 3rem;
    }

    .title {
        font-size: 30px !important;
    }

    .header-container {
        padding: 30px !important;
    }

}

@media only screen and (max-width: 435px) {
    .vid-text {
        font-size: 5rem;
    }

    .vid-text-2 {
        margin-top: 8rem;
    }

    .section-2-body {
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 410px) {
    .sec-2-ht {
        font-size: 1.5rem;
    }


    .lp-img-cont-text {
        padding-top: 1rem;
        padding-bottom: 1rem;

    }

    .aatwpic-lp {
        width: 90%;
        height: auto;
        padding: 1rem;
    }

    .tppic-lp {
        width: auto;
        padding: 1rem;
    }

    .section-5-lp {
        padding-top: 50rem;
        height: 30rem;
    }

    .section-6-lp {
        padding-top: 2rem;
    }

    .sec-5-ht-1 {
        padding-top: 0rem;
        font-size: 6rem;
    }
}

@media only screen and (max-width: 400px) {

    .sec-2-ht-t3 {
        font-size: 23px;   
    }

    .sec-2-ht-t4 {
        font-size: 25px;
    }

    .sec-2-ht-t5 {
        font-size: 20px;
    }  

    .sec-2-ht-t6 {
    font-size: 28px;
}

    #fullscreen-video {
        height: fit-content;
        width: 100%;
        height: 272px;
    }

    .lp-video {
        height: fit-content;
    }

    .vid-text {
        font-size: 5rem;
    }

    .vid-text-2 {
        font-size: 1.5rem;
        margin-top: 7rem;
    }

    .btn {
        font-size: .8rem;
        padding: 15px 20px;
    }

    .btn-container {
        margin-top: 11rem;
    }

    .section-2-body {
        padding-top: 1rem;
    }

    .sec-2-ht {
        font-size: 21px;
        white-space: pre-line;
        text-decoration: none;
    }

    .sec-2-ht-2 {
        font-size: 1.7rem;
        white-space: pre-line;
        text-decoration: none;
    }

    .sec-2-img-container {
        display: flex;
        flex-direction: column;

    }

    .aatwpic-lp {
        width: 90%;
        height: auto;
        padding: 1rem;

    }

    .lp-img-cont-text {
        padding-top: 1rem;
        font-family: ironmonger;
    }

    .tppic-lp {
        width: auto;
        padding: 1rem;

    }

    .section-4-lp {
        display: none;
    }

    .section-5-lp {
        display: flex;
        flex-direction: column;
        padding-top: 26rem;
    }

    .sec-5-ht-1 {
        font-size: 5rem;
        white-space: nowrap;
    }

    .jdf-lp-map {
        width: 90%;
    }

    .sec-6-ht {
        font-size: 20px;
    }

    .header-container {
        padding: 25px !important;
    }

    .title {
        font-size: 34px !important;
    }
}

.btn-lp {
    background-color: #fbeee0;
    border: 2px solid #422800;
    border-radius: 30px;
    box-shadow: #422800 4px 4px 0 0;
    color: #422800;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.btb {
    text-decoration: none;
    visibility: hidden;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px; /* Adjust padding as needed */
    background-color: #fff; /* Ensure background color is white */
    max-width: 100%; /* Adjust max width as needed */
    margin: 0 auto; /* Center container horizontally */
  }
  
  .title {
    margin-bottom: 20px; /* Space between title and image */
    font-size: 52px;
    letter-spacing: 7px;
    text-align: center;
    font-family: "Rye", serif;
    font-weight: 400;
    font-style: normal;
    background: linear-gradient(to top, #ac0808, #474747); /* Define the gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
     background-clip: text;
    text-fill-color: transparent;

  }
  
  .image {
    max-width: 100%;
    height: auto;
    display: block;
  }
  