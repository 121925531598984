.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .notfound-container h1 {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  
  .notfound-container p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .home-link {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .home-link:hover {
    text-decoration: underline;
  }
  